import { Modal } from "flowbite";
import { toastSuccess, toastWarning } from "../chunks/messages";
import { validateResponse } from "../chunks/validators";

let objectType;
let objectId;
let successMessage;
let $reportModalEl;
let reportModal;

export function addReportButtonListener() {
    document.querySelectorAll(".report").forEach((button) =>
        button.addEventListener("click", () => {
            objectType = button.getAttribute("data-object-type");
            objectId = button.getAttribute("data-object-id");

            if (objectType === "article") {
                successMessage = articleReportSuccessMessage;
            } else if (objectType === "comment") {
                successMessage = commentReportSuccessMessage;
            } else {
                successMessage = ideaReportSuccessMessage;
            }

            reportModal.show();
        }),
    );

    document
        .querySelectorAll('[data-modal-hide="report"]')
        .forEach((button) =>
            button.addEventListener("click", () => reportModal.hide()),
        );
}

async function postReport(form) {
    if (!isAuthenticated) window.location.href = loginRedirectURL;

    reportModal.hide();
    const formData = new FormData(form);
    const reportData = Object.fromEntries(formData.entries());

    let baseURL = "/api/articles/";
    if (objectType === "comment") {
        baseURL = "/api/articles/comments/";
    } else if (objectType === "idea") {
        baseURL = "/api/ideas/";
    }
    const reportURL = baseURL + objectId + "/report/";

    try {
        const response = await window.$axios.post(reportURL, reportData);
        validateResponse(response, true);
        toastSuccess(successMessage);
        form.reset();

        if (GA_ENABLED) {
            gtag("event", `report`, { type: objectType });
        }
    } catch (error) {
        toastWarning(error);
    }
}

const reportForm = document.querySelector("#report-form");
if (reportForm) {
    reportForm.addEventListener("submit", async (e) => {
        e.preventDefault();
        await postReport(reportForm);
    });
}

document.addEventListener("DOMContentLoaded", () => {
    $reportModalEl = document.querySelector("#report");
    const modalOptions = {
        backdrop: "dynamic",
        closable: false,
    };
    if ($reportModalEl) {
        reportModal = new Modal($reportModalEl, modalOptions);
    }

    addReportButtonListener();
});
