import { Modal } from "flowbite";
import { toastSuccess, toastWarning } from "../chunks/messages";
import { validateResponse } from "../chunks/validators";

let changeStatusObjectType;
let changeStatusObjectId;
let successMessage;
let $changeStatusModalEl;
let changeStatusModal;

export function addChangeStatusButtonListener() {
    document.querySelectorAll(".article-change-status").forEach((button) =>
        button.addEventListener("click", () => {
            changeStatusObjectType = "articles";
            changeStatusObjectId = button.getAttribute("data-article-id");
            successMessage = articleChangeStatusSuccessMessage;
            changeStatusModal.show();
        }),
    );

    document.querySelectorAll(".idea-change-status").forEach((button) =>
        button.addEventListener("click", () => {
            changeStatusObjectType = "ideas";
            changeStatusObjectId = button.getAttribute("data-idea-id");
            successMessage = ideaChangeStatusSuccessMessage;
            changeStatusModal.show();
        }),
    );

    document.querySelectorAll(".comment-change-status").forEach((button) =>
        button.addEventListener("click", () => {
            changeStatusObjectType = "comments";
            changeStatusObjectId = button.getAttribute("data-comment-id");
            successMessage = commentChangeStatusSuccessMessage;
            changeStatusModal.show();
        }),
    );

    document
        .querySelectorAll('[data-modal-hide="change-status"]')
        .forEach((button) =>
            button.addEventListener("click", () => changeStatusModal.hide()),
        );
}

async function postChangeStatus(form) {
    if (!isAuthenticated) window.location.href = loginRedirectURL;

    changeStatusModal.hide();
    const formData = new FormData(form);
    const changeStatusData = Object.fromEntries(formData.entries());

    let baseURL = "/api/articles/";
    if (changeStatusObjectType === "comments") {
        baseURL += "comments/";
    } else if (changeStatusObjectType === "ideas") {
        baseURL = "/api/ideas/";
    }
    const changeStatusURL = baseURL + changeStatusObjectId + "/change_status/";

    try {
        const response = await window.$axios.post(
            changeStatusURL,
            changeStatusData,
        );
        validateResponse(response, true);
        toastSuccess(successMessage);
        form.reset();
    } catch (error) {
        toastWarning(error);
    }
}

const changeStatusForm = document.querySelector("#change-status-form");
if (changeStatusForm) {
    changeStatusForm.addEventListener("submit", async (e) => {
        e.preventDefault();
        await postChangeStatus(changeStatusForm);
    });
}

document.addEventListener("DOMContentLoaded", () => {
    const modalOptions = {
        backdrop: "dynamic",
        closable: false,
    };
    $changeStatusModalEl = document.querySelector("#change-status");
    if ($changeStatusModalEl) {
        changeStatusModal = new Modal($changeStatusModalEl, modalOptions);
    }

    addChangeStatusButtonListener();
});

const statuses = document.querySelector("#statuses");
const ban_reasons = document.querySelector("#ban_reasons");

if (statuses) {
    statuses.addEventListener("change", (e) => {
        if (e.target.value === "banned") {
            ban_reasons.removeAttribute("disabled");
        } else {
            ban_reasons.setAttribute("disabled", true);
        }
    });
}
