import { toastWarning } from "../chunks/messages";
import { validateResponse } from "../chunks/validators";

function getFollowingButton(username) {
    return `
            <button data-user-id="${username}" action="remove" class="follow-user w-full flex items-center justify-center rounded-lg bg-white text-black text-xs border-1 py-1.5 sm:px-5 sm:py-2.5 dark:bg-primary-900 dark:text-primary-500">
                <i class="mr-3 fa-solid fa-check icon"></i> <i class="hidden mr-3 fa-solid fa-spinner animate-spin loading"></i> ${following}
            </button>
            `;
}

function getFollowButton(username) {
    return `
        <button data-user-id="${username}" action="add" class="follow-user w-full flex items-center justify-center rounded-lg bg-black text-white text-xs py-1.5 sm:px-5 sm:py-2.5 dark:bg-primary-500 dark:text-white dark:hover:bg-primary-500 dark:hover:text-white">
            <i class="mr-3 fa-solid fa-plus icon"></i> <i class="hidden mr-3 fa-solid fa-spinner animate-spin loading"></i> ${follow}
        </button>
        `;
}

async function followUser(button) {
    if (!button) return;

    if (!isAuthenticated) {
        window.location.href = loginRedirectURL;
        return;
    }

    const username = button.getAttribute("data-user-id");
    const action = button.getAttribute("action");
    const followingButtons = document.querySelector(
        `#follow-user-buttons-${username}`,
    );

    button.setAttribute("disabled", true);
    button.querySelector(".loading").classList.remove("hidden");
    button.querySelector(".icon").classList.add("hidden");

    try {
        const response = await window.$axios.post(
            `/api/profiles/${username}/follow/`,
            {
                is_following: action === "add",
            },
        );
        validateResponse(response, true);

        followingButtons.innerHTML =
            action === "add"
                ? getFollowingButton(username)
                : getFollowButton(username);

        if (GA_ENABLED) {
            gtag("event", "user_follow", { action: action });
        }

        addEventListeners();
    } catch (error) {
        toastWarning(error);
        button.removeAttribute("disabled");
        button.querySelector(".loading").classList.add("hidden");
        button.querySelector(".icon").classList.remove("hidden");
    }
}

function addEventListeners() {
    document.querySelectorAll(".follow-user").forEach((button) =>
        button.addEventListener("click", async (e) => {
            e.preventDefault();
            await followUser(e.target);
        }),
    );
}

addEventListeners();
