import { Modal } from "flowbite";
import { toastSuccess, toastWarning } from "../chunks/messages";
import { isPostShortcut } from "../chunks/utils";
import { isCommentFormValid, validateResponse } from "../chunks/validators";

let $modalEl;
let commentEditModal;
const commentEditFormSubmitButton = document.querySelector(
    "#comment-edit-form-submit-button",
);
const commentEditFormContent = document.querySelector(
    "#comment-edit-form-content",
);

let commentId;
let commentContent;

export function addCommentEditModalListeners() {
    document.querySelectorAll(".comment-edit").forEach((button) =>
        button.addEventListener("click", () => {
            commentId = button.getAttribute("data-comment-id");
            commentContent = document.querySelector(
                `#comment-${commentId}-content`,
            );
            commentEditFormContent.value = commentContent.textContent;
            commentEditModal.show();
        }),
    );

    document
        .querySelectorAll("[data-modal-hide='comment-edit']")
        .forEach((button) => {
            button.addEventListener("click", () => commentEditModal.hide());
        });
}

async function updateComment(form) {
    if (!isAuthenticated) window.location.href = loginRedirectURL;

    const formData = new FormData(form);
    const commentData = Object.fromEntries(formData.entries());
    const commentEditURL = `/api/articles/comments/${commentId}/`;

    try {
        const response = await window.$axios.put(commentEditURL, commentData);
        validateResponse(response, true);

        toastSuccess(commentEditSuccessMessage);
        commentEditModal.hide();
        commentContent.textContent = commentEditFormContent.value;

        if (GA_ENABLED) {
            gtag("event", `comment-edit`);
        }
    } catch (error) {
        toastWarning(error);
    }
}

const commentEditForm = document.querySelector("#comment-edit-form");
if (commentEditForm) {
    commentEditForm.addEventListener("submit", async (e) => {
        e.preventDefault();
        commentEditFormSubmitButton.setAttribute("disabled", true);
        await updateComment(commentEditForm);
        commentEditFormSubmitButton.removeAttribute("disabled");
    });

    commentEditForm.addEventListener("keydown", async (e) => {
        if (isPostShortcut(e) && isCommentFormValid(commentEditForm)) {
            commentEditFormSubmitButton.setAttribute("disabled", true);
            await updateComment(commentEditForm);
            commentEditFormSubmitButton.removeAttribute("disabled");
        }
    });
}

document.addEventListener("DOMContentLoaded", () => {
    const modalOptions = {
        backdrop: "dynamic",
        closable: false,
    };

    $modalEl = document.querySelector("#comment-edit");
    if ($modalEl) {
        commentEditModal = new Modal($modalEl, modalOptions);
    }

    addCommentEditModalListeners();
});
