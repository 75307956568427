import { toastSuccess, toastWarning } from "../chunks/messages";
import { validateResponse } from "../chunks/validators";

async function pinObject(button) {
    const pinned = button.getAttribute("data-object-is-pinned") === "true";
    const objectType = button.getAttribute("data-object-type");
    const objectId = button.getAttribute("data-object-id");
    const text = button.querySelector("span");
    const pinIcon = document.querySelector(
        `#${objectType}-${objectId}-pin-icon`,
    );

    button.setAttribute("disabled", true);

    let baseURL = "/api/articles";
    if (objectType === "comment") {
        baseURL = "/api/articles/comments";
    } else if (objectType === "idea") {
        baseURL = "/api/ideas";
    }

    try {
        const response = await window.$axios.post(
            `${baseURL}/${objectId}/pin/`,
            { is_pinned: !pinned },
        );
        validateResponse(response, true);
        if (response.data.is_pinned) {
            toastSuccess(pinSuccessMessage);
            text.textContent = unpinText;
            button.setAttribute("data-object-is-pinned", "true");
            pinIcon?.classList.remove("hidden");
        } else {
            toastSuccess(unpinSuccessMessage);
            text.textContent = pinText;
            button.setAttribute("data-object-is-pinned", "false");
            pinIcon?.classList.add("hidden");
        }

        if (GA_ENABLED) {
            gtag("event", `pin`, { type: objectType });
        }
    } catch (error) {
        const error_message = error.response?.data?.error_message;
        if (error_message) {
            toastWarning(error_message);
        } else {
            toastWarning(error);
        }
    }

    button.removeAttribute("disabled");
}

const listenedButtons = [];
export function addPinButtonListeners() {
    document.querySelectorAll(".pin").forEach((button) => {
        if (!listenedButtons.includes(button)) {
            button.addEventListener("click", () => pinObject(button));
            listenedButtons.push(button);
        }
    });
}

addPinButtonListeners();
