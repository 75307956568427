"use strict";
document.addEventListener("DOMContentLoaded", () => {
    document.querySelectorAll(".word-counter").forEach((counter) => {
        const inputId = counter.getAttribute("data-input-id");
        const input = document.querySelector(`#${inputId}`);
        // Set default value
        counter.textContent = input.value.length.toString();
        input === null || input === void 0 ? void 0 : input.addEventListener("input", () => {
            counter.textContent = input.value.length.toString();
        });
    });
});
