class Infinite {
    constructor(options, active = true) {
        const defaults = {
            container: "auto",
            items: ".infinite-item",
            more: ".infinite-more-link",
            offset: "bottom-in-view",
            loadingClass: "infinite-loading",
            triggers: ".load-more-objects",
            initialLoad: false,
            onBeforePageLoad: $.noop,
            onAfterPageLoad: $.noop,
        };

        this.options = $.extend({}, defaults, options);

        this.container = this.options.element;
        if (this.options.container !== "auto") {
            this.container = this.options.container;
        }
        this.$container = $(this.container);
        this.$more = $(this.options.more);
        if (this.$more.length && active) {
            this.active = true;
        }

        this.$triggers = $(this.options.triggers);
        if (this.$triggers) {
            this.$triggers.on("click", () => this.loadMore());
        }

        if (this.options.initialLoad) {
            this.loadMore();
        }
    }

    loadMore() {
        if (!this.active || !this.$more) return;

        this.options.onBeforePageLoad();
        this.$container.addClass(this.options.loadingClass);

        $.get($(this.options.more).attr("href"), (data) => {
            const $data = $($.parseHTML(data, true));
            let $newMore = $data.find(this.options.more);

            let $items = $data.find(this.options.items);
            if (!$items.length) {
                $items = $data.filter(this.options.items);
            }

            this.$container.append($items);
            this.$container.removeClass(this.options.loadingClass);

            if (!$newMore.length) {
                $newMore = $data.filter(this.options.more);
            }
            if ($newMore.length) {
                this.$more.replaceWith($newMore);
                this.$more = $newMore;
            } else {
                this.$more.remove();
                if (this.$triggers) {
                    this.$triggers.remove();
                }
            }

            this.options.onAfterPageLoad($items);
        });
    }
}

export default Infinite;
