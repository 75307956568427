export function validateResponse(response, throwError = false) {
    let errors = [];

    if (response.data.error_message) {
        errors.push(response.data.error_message);
    }

    if (response.status === 400) {
        Object.values(response.data).forEach((value) => {
            if (Array.isArray(value)) {
                errors = errors.concat(value);
            } else {
                errors.push(value);
            }
        });
    }

    if (response.status === 401) {
        window.location.href = loginRedirectURL;
    }

    if (throwError && errors.length) {
        throw errors[0];
    }

    return errors;
}

export function isCommentFormValid(form) {
    return !!form.content.value;
}

window.validateResponse = validateResponse;
