"use strict";
document.addEventListener("DOMContentLoaded", () => {
    const content = document.querySelector("#article-content");
    if (!content) {
        return;
    }
    document.addEventListener("scroll", () => {
        const windowHeight = window.innerHeight;
        const contentTop = content.offsetTop;
        const contentHeight = content.offsetHeight;
        const scrollTop = window.scrollY;
        const likeButton = document.querySelector("#like-button");
        const initialIcon = document.querySelector(".initial");
        const beatingIcon = document.querySelector(".beating");
        const scrollPercent = ((scrollTop - contentTop + windowHeight) / contentHeight) * 100;
        if (scrollPercent > 70 &&
            !(initialIcon === null || initialIcon === void 0 ? void 0 : initialIcon.classList.contains("hidden")) &&
            (likeButton === null || likeButton === void 0 ? void 0 : likeButton.getAttribute("job")) === "like") {
            initialIcon === null || initialIcon === void 0 ? void 0 : initialIcon.classList.add("hidden");
            beatingIcon === null || beatingIcon === void 0 ? void 0 : beatingIcon.classList.remove("hidden");
        }
    });
});
