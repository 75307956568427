import { toastWarning } from "../chunks/messages";

document.querySelectorAll(".like-button").forEach((button) =>
    button.addEventListener("click", async (e) => {
        e.preventDefault();

        if (!isAuthenticated) {
            window.location = loginRedirectURL;
            return;
        }

        const initialIcon = button.querySelector(".initial");
        const beatingIcon = button.querySelector(".beating");
        const objectId = button.getAttribute("data-object-id");
        const objectType = button.getAttribute("data-object-type");
        const is_liked = button.getAttribute("job") === "like";

        let baseURL = "/api/articles";
        if (objectType === "idea") {
            baseURL = "/api/ideas";
        }

        const response = await window.$axios.post(
            `${baseURL}/${objectId}/like/`,
            { is_liked },
        );
        if (response.status === 200) {
            const data = response.data;
            const siblingLikeButtons = document.querySelectorAll(
                `.like-button[data-object-type="${objectType}"][data-object-id="${objectId}"]`,
            );

            for (let sibling of siblingLikeButtons) {
                const likeCount = sibling.querySelector(".like_count");
                if (likeCount) {
                    likeCount.textContent = data.like_count;
                }

                sibling.querySelectorAll(`.like-icon`).forEach((heartIcon) => {
                    if (data.is_liked) {
                        heartIcon.setAttribute("data-prefix", "fas");
                        button.setAttribute("job", "dislike");

                        initialIcon?.classList.remove("hidden");
                        beatingIcon?.classList.add("hidden");
                    } else {
                        heartIcon.setAttribute("data-prefix", "far");
                        button.setAttribute("job", "like");

                        initialIcon?.classList.add("hidden");
                        beatingIcon?.classList.remove("hidden");
                    }
                });
            }

            if (GA_ENABLED) {
                gtag("event", "like", {
                    action: data.is_liked ? "add" : "remove",
                });
            }
        } else if (response.status === 404) {
            document.location.href = "/404";
        } else if (response.status === 403) {
            if (objectType === "idea") {
                toastWarning(cantLikeOwnArticleWarning);
            } else {
                toastWarning(cantLikeOwnIdeaWarning);
            }
        } else {
            document.location.href = response.url;
        }
    }),
);
