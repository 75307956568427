document.addEventListener("DOMContentLoaded", () => {
    // On first page load
    setCheck();
    setTheme();
    setButtonStyles();
    // This has to be done so if the system theme changed server doesn't get the outdated theme
    setCookie("dark", isDark());
    document
        .querySelectorAll("input[name='theme-switcher']")
        .forEach((input) => {
            input.addEventListener("change", (e) => {
                switch (e.target.id) {
                    case "auto-radio":
                        localStorage.removeItem("theme");
                        break;
                    case "light-radio":
                        localStorage.theme = "light";
                        break;
                    case "dark-radio":
                        localStorage.theme = "dark";
                        break;
                }
                setButtonStyles();
                setTheme();
                setCookie("dark", isDark());
            });
        });
});

function setTheme() {
    const images = document.querySelectorAll("img.logo");
    if (isDark()) {
        document.documentElement.classList.add("dark");
        logo = localStorage.darkLogo;
        images.forEach((img) => {
            img.src = localStorage.darkLogo;
        });
    } else {
        document.documentElement.classList.remove("dark");
        logo = localStorage.lightLogo;
        images.forEach((img) => {
            img.src = localStorage.lightLogo;
        });
    }
}

function isDark() {
    return (
        localStorage.theme === "dark" ||
        (!("theme" in localStorage) &&
            window.matchMedia("(prefers-color-scheme: dark)").matches)
    );
}

function setCheck() {
    const inputs = document.querySelectorAll("input[name='theme-switcher']");
    if (!inputs.length) return;

    if (localStorage.theme === "dark") {
        inputs[2].checked = true;
    } else if (localStorage.theme === "light") {
        inputs[1].checked = true;
    } else {
        inputs[0].checked = true;
    }
}

function setButtonStyles() {
    document
        .querySelectorAll("input[name='theme-switcher']")
        .forEach((input) => {
            const label = document.querySelector(`label[for="${input.id}"]`);
            if (input.checked) {
                label.classList.add("text-blue-500");
                label.classList.add("dark:text-blue-500");
                label.classList.remove("dark:text-primary-300");
                label.classList.remove("text-primary-900");
            } else {
                label.classList.remove("text-blue-500");
                label.classList.remove("dark:text-blue-500");
                label.classList.add("dark:text-primary-300");
                label.classList.add("text-primary-900");
            }
        });
}

function setCookie(cname, cValue, exDays = 365) {
    const d = new Date();
    d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cValue + ";" + expires + ";path=/";
}

function getCookie(cName) {
    let name = cName + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
