import { Drawer, Dropdown, Modal } from "flowbite";
import { toastSuccess, toastWarning } from "../chunks/messages";
import { isPostShortcut } from "../chunks/utils";
import { isCommentFormValid, validateResponse } from "../chunks/validators";
import { addChangeStatusButtonListener } from "../moderators/articles";
import { addCommentEditModalListeners } from "./comment-edit";
import { addPinButtonListeners } from "./pin";
import { addReportButtonListener } from "./report";
import escape from "lodash.escape";
import Infinite from "./infinite";

const activatedInfiniteScrolls = {};
const dropdowns = {};
let drawerIsTriggered = false;
let commentIdToDelete;

let $modalEl;
let commentDeleteModal;

document
    .querySelectorAll("[data-modal-hide='comment-delete-confirm']")
    .forEach((button) => {
        button.addEventListener("click", () => commentDeleteModal.toggle());
    });

const dropdownOptions = {
    placement: "bottom",
    triggerType: "click",
    offsetSkidding: 0,
    offsetDistance: 10,
    delay: 300,
    ignoreClickOutsideClass: false,
};

const drawerOptions = {
    placement: "right",
    backdrop: true,
    bodyScrolling: false,
    onShow: () => {
        if (drawerIsTriggered) return;
        drawerIsTriggered = true;

        $(".comments-loading").hide();
        window.drawerInfiniteScroll = new Infinite({
            element: $(".infinite-comments-container")[0],
            more: ".infinite-more-comments-link",
            items: ".comment",
            initialLoad: true,
            onBeforePageLoad: () => {
                $(".comments-loading").show();
            },
            onAfterPageLoad: ($items) => {
                $(".comments-loading").hide();
                addCommentListeners();
            },
        });
    },
};

function increaseTotalCommentCount() {
    document
        .querySelectorAll(".total-comment-count")
        .forEach(($el) => ($el.textContent = parseInt($el.textContent) + 1));
}

function showReplyToggler(toggler) {
    toggler.classList.remove("hidden");
    toggler.parentElement.classList.remove("justify-end");
    toggler.parentElement.classList.add("justify-between");
}

function createDropdowns() {
    document.querySelectorAll(".comment-options-button").forEach(($button) => {
        const commentId = $button.getAttribute("comment-id");
        if (commentId in dropdowns) return;

        const dropdownId = $button.getAttribute("data-dropdown-toggle");
        const $dropdown = document.querySelector(`#${dropdownId}`);
        dropdowns[commentId] = new Dropdown(
            $dropdown,
            $button,
            dropdownOptions,
        );
    });

    document.querySelectorAll(".comment-delete-button").forEach((button) => {
        button.addEventListener("click", () => {
            commentDeleteModal.show();
            commentIdToDelete = button.getAttribute("comment-id");
        });
    });
}

function removeComment(comment_id) {
    document
        .querySelectorAll(
            `.comment[comment-id="${comment_id}"], .reply[comment-id="${comment_id}"]`,
        )
        .forEach((comment) => comment.remove());
}

async function deleteComment() {
    try {
        const response = await window.$axios.delete(
            `/api/articles/comments/${commentIdToDelete}/`,
        );
        if (response.status !== 204) {
            validateResponse(response, true);
        }
        toastSuccess(commentDeleteSuccessMessage);
        removeComment(commentIdToDelete);

        if (GA_ENABLED) {
            gtag("event", `comment-delete`);
        }
    } catch (error) {
        toastWarning(error);
    }
}

async function postComment(form) {
    const formData = new FormData(form);

    if (!isAuthenticated) window.location.href = loginRedirectURL;

    try {
        const response = await window.$axios.post(commentsURL, formData);
        validateResponse(response, true);

        form.querySelector("[name='content']").value = "";
        document
            .querySelector(".infinite-comments-container")
            .prepend(generateCommentTag(response.data));
        addCommentListeners();
        increaseTotalCommentCount();
        toastSuccess(commentSuccessMessage);

        if (GA_ENABLED) {
            gtag("event", `comment-create`);
        }
    } catch (error) {
        toastWarning(error);
    }
}

async function postReply(form, commentId) {
    const formData = new FormData(form);
    const replyListToggler = document.querySelector(
        `button[data-toggle-reply-list='replies-${commentId}']`,
    );
    const replyCount = replyListToggler.querySelector("span.reply-count");
    const dynamicText = replyListToggler.querySelector("span.text");
    const replyList = document.querySelector(`#replies-${commentId}`);

    if (!isAuthenticated) window.location.href = loginRedirectURL;

    try {
        const response = await window.$axios.post(commentsURL, formData);
        validateResponse(response, true);
        form.querySelector("[name='content']").value = "";
        document
            .querySelector(`.infinite-replies-container-${commentId}`)
            .append(generateReplyTag(response.data));
        replyListToggler.click();
        replyList.classList.remove("hidden");
        replyCount.textContent = parseInt(replyCount.textContent) + 1;
        showReplyToggler(replyCount.parentElement);
        increaseTotalCommentCount();
        dynamicText.textContent = hideReplies;

        createDropdowns();
        addCommentEditModalListeners();
        addPinButtonListeners();

        toastSuccess(replySuccessMessage);

        if (GA_ENABLED) {
            gtag("event", `comment-reply`);
        }
    } catch (error) {
        toastWarning(error);
    }
}

function addToggleReplyListListener() {
    document
        .querySelectorAll("button[data-toggle-reply-list]")
        .forEach((button) => {
            if (button.hasAttribute("listening")) return;
            button.setAttribute("listening", "");

            const replyListId = button.getAttribute("data-toggle-reply-list");
            const replyList = document.querySelector(`#${replyListId}`);
            const dynamicText = button.querySelector("span.text");
            const commentId = replyList.getAttribute("data-comment-id");

            button.addEventListener("click", () => {
                replyList.classList.toggle("hidden");
                dynamicText.textContent = replyList.classList.contains("hidden")
                    ? replies
                    : hideReplies;

                if (activatedInfiniteScrolls[commentId]) return;
                activatedInfiniteScrolls[commentId] = true;

                const infiniteRepliesContainer = $(
                    `.infinite-replies-container-${commentId}`,
                )[0];
                infiniteRepliesContainer.innerHTML = "";

                window.repliesInfiniteScroll = new Infinite({
                    element: infiniteRepliesContainer,
                    more: `.infinite-more-replies-${commentId}-link`,
                    items: `.replies-${commentId}`,
                    initialLoad: true,
                    onBeforePageLoad: () => {
                        $(`.replies-loading-${commentId}`).removeClass(
                            "hidden",
                        );
                    },
                    onAfterPageLoad: ($items) => {
                        $(`.replies-loading-${commentId}`).addClass("hidden");
                        createDropdowns();
                        addCommentEditModalListeners();
                        addPinButtonListeners();
                    },
                });
            });
        });
}

function addReplyFormSubmitListener() {
    document.querySelectorAll(".reply-form").forEach((form) => {
        if (form.hasAttribute("listening")) return;
        form.setAttribute("listening", "");

        const commentId = form.getAttribute("data-comment-id");

        form.addEventListener("keydown", async (e) => {
            if (isPostShortcut(e) && isCommentFormValid(form)) {
                await postReply(form, commentId);
            }
        });

        form.addEventListener("submit", async (e) => {
            e.preventDefault();
            await postReply(e.target, commentId);
        });
    });
}

function addToggleReplyFormListener() {
    document
        .querySelectorAll("button[data-toggle-reply-form]")
        .forEach((button) => {
            if (button.hasAttribute("listening")) return;
            button.setAttribute("listening", "");
            button.addEventListener("click", (e) => {
                const replyFormId = button.getAttribute(
                    "data-toggle-reply-form",
                );

                const replyForm = document.querySelector(`#${replyFormId}`);
                const isHidden = replyForm.classList.contains("hidden");
                document
                    .querySelectorAll(".reply-form")
                    .forEach((form) => form.classList.add("hidden"));

                if (isHidden) {
                    replyForm.classList.remove("hidden");
                }
            });
        });
}

function addCommentListeners() {
    addToggleReplyListListener();
    addReplyFormSubmitListener();
    addToggleReplyFormListener();
    addReportButtonListener();
    addChangeStatusButtonListener();
    createDropdowns();
    addCommentEditModalListeners();
    addPinButtonListeners();
}

document.addEventListener("DOMContentLoaded", () => {
    const modalOptions = {
        backdrop: "dynamic",
        closable: false,
    };

    $modalEl = document.querySelector("#comment-delete-confirm");
    if ($modalEl) {
        commentDeleteModal = new Modal($modalEl, modalOptions);
    }

    const commentDeleteForm = document.querySelector("#comment-delete-form");
    if (commentDeleteForm !== null) {
        commentDeleteForm.addEventListener("submit", (e) => {
            e.preventDefault();
            deleteComment();
            commentDeleteModal.hide();
        });
    }

    const $targetEl = document.getElementById("comments");
    if ($targetEl) {
        const drawer = new Drawer($targetEl, drawerOptions);

        const queryString = window.location.search;
        const parameters = Object.fromEntries(new URLSearchParams(queryString));

        if (parameters.drawer === "open") {
            drawer.show();
        }

        document
            .querySelectorAll(".toggle-drawer")
            .forEach((toggler) =>
                toggler.addEventListener("click", () => drawer.toggle()),
            );
    }

    const commentForms = document.querySelectorAll(".comment-form");
    commentForms.forEach((commentForm) => {
        commentForm.addEventListener("keydown", async (e) => {
            if (isPostShortcut(e) && isCommentFormValid(commentForm)) {
                await postComment(commentForm);
            }
        });

        commentForm.addEventListener("submit", async (e) => {
            e.preventDefault();
            await postComment(e.target);
        });
    });
});

function getOptionsMenu(comment) {
    let pinButton = "";
    if (comment.author.id == userId) {
        pinButton = `
        <li>
            <button data-object-is-pinned="${
                comment.is_pinned
            }" data-object-type="comment" data-object-id="${
                comment.id
            }" class="pin flex flex-no-wrap items-center text-start w-full px-4 py-2 hover:bg-primary-100 dark:hover:bg-primary-600 dark:hover:text-white">
                <i class="fas fa-thumb-tack mr-3"></i> <span>${
                    comment.is_pinned ? unpinText : pinText
                }</span>
            </button>
        </li> 
        `;
    }

    return `
        <button id="comment-options-button-${comment.id}" data-dropdown-toggle="comment-options-${comment.id}" comment-id="${comment.id}" class="comment-options-button text-primary-900 dark:text-white">
            <i class="w-4 h-4 fa-solid fa-ellipsis-vertical"></i>
        </button>

        <div id="comment-options-${comment.id}" class="z-10 hidden bg-white divide-y divide-primary-100 rounded-lg shadow w-44 dark:bg-primary-700">
            <ul class="text-sm text-primary-700 dark:text-primary-200" aria-labelledby="comment-options-button-${comment.id}">
                ${pinButton}
                <li>
                    <button data-comment-id="${comment.id}"
                        class="comment-edit block text-start w-full px-4 py-2 hover:bg-primary-100 dark:hover:bg-primary-600 dark:hover:text-white">
                        <i class="fa-solid fa-flag mr-3"></i> ${commentEditText}
                    </button>
                </li>
                <li>
                    <button data-modal-target="comment-delete-confirm-${comment.id}" comment-id="${comment.id}" class="comment-delete-button block text-start text-red-600 w-full px-4 py-2 hover:bg-primary-100 dark:hover:bg-primary-600 dark:hover:text-white">
                        <i class="fa-solid fa-trash  mr-3"></i> ${deleteCommentText}
                    </button>
                </li>
            </ul>
        </div>
    `;
}

function generateCommentTag(comment) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(
        `
        <article comment-id="${
            comment.id
        }" class="comment border-t border-primary-200 dark:border-primary-700 py-3 mt-3 text-base bg-white dark:bg-primary-900">
                <footer class="flex justify-between items-center mb-2">
                    <div>
                        <p class="inline-flex items-center text-sm text-primary-900 dark:text-white">
                            <a class="inline-flex items-center"
                               href="${comment.author_url}">
                                <img height="24" width="24" alt="${userFullName}"
                                     class="mr-2 w-6 h-6 rounded-full"
                                     src="${userAvatar}"/>
                                <span>${userFullName}</span>
                            </a>
                            <span class="px-1">·</span>
                            <time class="text-primary-500" datetime="${
                                comment.created_date_humanized
                            }"
                                title="${comment.created_date}">${
                                    comment.created_date_humanized
                                }
                            </time>
                        </p>
                    </div>
                    ${getOptionsMenu(comment)}
                </footer>
                <p class="text-primary-500 dark:text-primary-400 break-words" id="comment-${
                    comment.id
                }-content">${escape(comment.content).replace(/\n/g, "<br>")}</p>
                <div class="flex items-center justify-end mt-4 space-x-4">
                    <button class="hidden flex items-center text-sm text-primary-500 hover:underline dark:text-primary-400" type="button" data-toggle-reply-list="replies-${
                        comment.id
                    }">
                        <i class="fas fa-comment mr-2"></i>
                        <span class="reply-count">0</span> &nbsp;<span class="text">${replies}</span>
                    </button>
                    <button class="flex items-center text-sm text-primary-500 hover:underline dark:text-primary-400" type="button" data-toggle-reply-form="reply-form-${
                        comment.id
                    }">
                        ${reply}
                    </button>
                </div>

                <form class="hidden my-3 reply-form" id="reply-form-${
                    comment.id
                }" data-comment-id="${comment.id}">
                    <input type="hidden" name="parent" value="${comment.id}">
                    <input type="hidden" name="article" value="${articleId}">
                    <div class="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-primary-200 dark:bg-primary-900 dark:border-primary-700">
                        <label class="sr-only" for="content">Your comment</label>
                        <textarea class="px-0 resize-none w-full text-base placeholder:text-base text-primary-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-primary-400 dark:bg-primary-900" id="content" name="content"
                                placeholder="${commentPlaceholder}"
                                maxlength="750"
                                required rows="6"></textarea>
                    </div>
                    <div class="flex items-center justify-end">
                        <button class="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800"
                                type="submit">
                            ${shareReply}
                        </button>
                    </div>
                </form>
                <div id="replies-${comment.id}" data-comment-id="${
                    comment.id
                }" class="infinite-replies-container-${
                    comment.id
                } hidden border-l border-primary-200 my-5">
                </div>
                <div class="replies-loading-${
                    comment.id
                } hidden flex flex-col justify-center items-center mb-4 mt-4" role="status">
                    <i class="fa-solid fa-spinner animate-spin"></i>
                    <span class="sr-only">${loading}</span>
                </div>        

                <a class="infinite-more-replies-${
                    comment.id
                }-link" href="${commentsURL}?article=${articleId}&parent=${
                    comment.id
                }"></a>
                
            </article>
        `,
        "text/html",
    );

    return doc.body.children[0];
}

function generateReplyTag(reply) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(
        `
            <article comment-id="${reply.id}" class="reply replies-${
                reply.parent_id
            } relative py-3 px-6 text-base bg-white dark:bg-primary-900">
                <div class="absolute w-3 h-3 bg-primary-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-primary-900 dark:bg-primary-700"></div>    
                <footer class="flex justify-between items-center mb-2">
                    <div>
                        <p class="inline-flex items-center text-sm text-primary-900 dark:text-white">
                            <a class="inline-flex items-center"
                               href="${reply.author_url}">
                                <img height="24" width="24" alt="${userFullName}"
                                     class="mr-2 w-6 h-6 rounded-full"
                                     src="${userAvatar}"/>
                                <span>${userFullName}</span>
                            </a>
                            <span class="px-1">·</span>
                            <time class="text-primary-500" datetime="${
                                reply.created_date_humanized
                            }"
                                title="${reply.created_date}">${
                                    reply.created_date_humanized
                                }
                            </time>
                        </p>
                    </div>
                    ${getOptionsMenu(reply)}
                </footer>
                <p class="text-primary-500 dark:text-primary-400 break-words" id="comment-${
                    reply.id
                }-content">${reply.content}</p>
            </article>
        `,
        "text/html",
    );

    return doc.body.children[0];
}
