"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function hasUnreadNotification(notifications) {
    for (const notification of notifications) {
        if (!notification.is_read)
            return true;
    }
    return false;
}
function getNotificationBadge(eventType) {
    if (eventType === "new_like") {
        return `
            <div class="absolute flex items-center justify-center w-5 h-5 ml-6 -mt-5 bg-red-600 border border-white rounded-full dark:border-primary-800">
                <div class="mb-1.5">
                    <i class="w-2 h-2 fas fa-heart text-white"></i>
                </div>
            </div>
        `;
    }
    else if (eventType === "new_follower") {
        return `
            <div class="absolute flex items-center justify-center w-5 h-5 ml-6 -mt-5 bg-primary-900 border border-white rounded-full dark:border-primary-800">
                <div class="text-primary-950 text-sm mb-1.5 dark:text-primary-400">
                    <i class="w-2 h-2 fas fa-user-plus text-white"></i>
                </div>
            </div>
        `;
    }
    else if (eventType === "new_comment") {
        return `
            <div class="absolute flex items-center justify-center w-5 h-5 ml-6 -mt-5 bg-green-400 border border-white rounded-full dark:border-primary-800">
                <div class="text-primary-950 text-sm mb-1.5 dark:text-primary-400">
                    <i class="w-2 h-2 fas fa-comment text-white"></i>
                </div>
            </div>
        `;
    }
    return "";
}
function readAllNotifications() {
    window.$axios.patch(`/api/notifications/read_all/`).then(() => {
        var _a;
        document.querySelectorAll(".notification").forEach((notification) => {
            notification.classList.remove("font-bold");
        });
        (_a = document.querySelector(".notification-bell")) === null || _a === void 0 ? void 0 : _a.classList.add("hidden");
    });
}
function changeNotificationStatus(notificationId, isRead) {
    window.$axios
        .patch(`/api/notifications/${notificationId}/`, {
        is_read: isRead,
    })
        .then(() => {
        document
            .querySelectorAll(`.notification-${notificationId}`)
            .forEach((notification) => {
            isRead
                ? notification.classList.remove("font-bold")
                : notification.classList.add("font-bold");
        });
        const bell = document.querySelector(".notification-bell");
        document.querySelector(".notification.font-bold")
            ? bell.classList.remove("hidden")
            : bell.classList.add("hidden");
        document
            .querySelectorAll(`.notification-action[data-notification-id="${notificationId}"]`)
            .forEach((button) => {
            if (button.getAttribute("action") === "read") {
                isRead
                    ? button.classList.remove("group-hover:block")
                    : button.classList.add("group-hover:block");
            }
            else if (button.getAttribute("action") === "unread") {
                isRead
                    ? button.classList.add("group-hover:block")
                    : button.classList.remove("group-hover:block");
            }
        });
    });
}
function setNotificationActionButtonListeners() {
    document.querySelectorAll(".notification-action").forEach((button) => {
        button.addEventListener("click", (e) => {
            e.preventDefault();
            if (button.getAttribute("action") === "read-all") {
                readAllNotifications();
                return;
            }
            const notificationId = parseInt(button.getAttribute("data-notification-id"));
            changeNotificationStatus(notificationId, button.getAttribute("action") === "read");
        });
    });
}
document.addEventListener("DOMContentLoaded", function () {
    const notificationsContainer = document.getElementById("notifications");
    const notificationsButton = document.getElementById("dropdownNotificationButton");
    function populateNotifications(notifications) {
        if (notificationsContainer === null)
            return;
        if (notificationsButton === null)
            return;
        if (notifications.length === 0) {
            const notificationElement = document.createElement("div");
            notificationElement.innerHTML = `
                <div class="m-2 dark:text-primary-500">
                    <p>Entek hiç hili bildiriş ýok.</p>
                </div>
            `;
            notificationsContainer.appendChild(notificationElement);
            return;
        }
        notificationsButton.innerHTML += `
            <div class="relative flex notification-bell ${hasUnreadNotification(notifications) ? "" : "hidden"}">
                <div class="relative inline-flex w-3 h-3 bg-red-500 border-2 border-white rounded-full -top-2 right-3 dark:border-primary-900"></div>
            </div>
        `;
        notificationsContainer.innerHTML = "";
        notifications.forEach((notification) => {
            const notificationElement = document.createElement("div");
            notificationElement.classList.add("notification");
            notificationElement.setAttribute("data-notification-id", notification.id.toString());
            notificationElement.setAttribute("data-notification-event-type", notification.event.type);
            const notificationBadge = getNotificationBadge(notification.event.type);
            let avatar = notification.event.creator.avatar;
            if (notification.event.creator.username == "system") {
                avatar = logo;
            }
            const url = `/notifications/${notification.id}/`;
            notificationElement.innerHTML = `
            <a href="${url}" class="notification notification-${notification.id} ${notification.is_read ? "" : "font-bold"} group flex px-4 py-3 hover:bg-primary-100 dark:hover:bg-primary-700">
                <div class="flex-shrink-0">
                    <img height="44px" width="44px" class="rounded-full w-10 h-10 ring-1 ring-primary-100 dark:ring-primary-700" src="${avatar}" alt="Profile image">
                    ${notificationBadge}
                </div>
                <div class="w-full pl-3">
                    <div class="flex justify-between">    
                        <p class="mb-1 text-primary-900 dark:text-white">@${notification.event.creator.username}</p>
                        <div>
                            <button data-tooltip-target="mark-as-read-${notification.id}" data-notification-id="${notification.id}" action="read" class="notification-action text-primary-600 dark:text-primary-500 hidden ${notification.is_read ? "" : "group-hover:block"}">
                                <i class="fas fa-envelope-open"></i>
                            </button>
                            <div id="mark-as-read-${notification.id}" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg opacity-0 tooltip dark:bg-primary-300 dark:text-primary-900">
                                Mark as read
                                <div class="tooltip-arrow" data-popper-arrow></div>
                            </div>
                            <button data-tooltip-target="mark-as-unread-${notification.id}" data-notification-id="${notification.id}" action="unread" class="notification-action text-primary-600 dark:text-primary-500 hidden ${notification.is_read ? "group-hover:block" : ""}">
                                <i class="fas fa-envelope"></i>
                            </button>
                            <div id="mark-as-unread-${notification.id}" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg opacity-0 tooltip dark:bg-primary-300 dark:text-primary-900">
                                Mark as unread
                                <div class="tooltip-arrow" data-popper-arrow></div>
                            </div>
                        </div>
                    </div>
                    <p class="mb-1 text-primary-900 dark:text-white">${notification.title}</p>
                    <p class="text-xs text-blue-600 dark:text-blue-500">${notification.created_date}</p>
                </div>
            </a>
            `;
            notificationsContainer.appendChild(notificationElement);
        });
        attachClickHandlers();
    }
    function fetchNotifications() {
        window.$axios
            .get("/api/notifications/?limit=5&type=web")
            .then((response) => {
            populateNotifications(response.data.results);
            setNotificationActionButtonListeners();
        });
    }
    function attachClickHandlers() {
        const notifications = document.querySelectorAll(".notification");
        notifications.forEach((element) => {
            element.addEventListener("click", () => {
                const eventType = element.getAttribute("data-notification-event-type");
                if (GA_ENABLED) {
                    gtag("event", "notification_click", {
                        event_type: eventType,
                    });
                }
            });
        });
    }
    if (notificationsButton !== null) {
        fetchNotifications();
    }
});
