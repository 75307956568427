"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const flowbite_1 = require("flowbite");
document.addEventListener("DOMContentLoaded", () => {
    const targetEl = document.querySelector("#publish-success");
    const options = {
        closable: true,
    };
    let modal;
    if (targetEl) {
        modal = new flowbite_1.Modal(targetEl, options);
        modal.show();
    }
    document
        .querySelectorAll('button[data-modal-hide="publish-success"]')
        .forEach((button) => button.addEventListener("click", () => {
        modal === null || modal === void 0 ? void 0 : modal.hide();
        const path = window.location.href.split("?")[0];
        window.history.replaceState({}, "", path);
    }));
});
