function recaptchaFormSubmitHandler(e, site_key, action) {
    /* Captures form submit and sets the value */
    e.preventDefault();
    grecaptcha.enterprise.ready(async () => {
        document.getElementById("g_recaptcha_response").value =
            await grecaptcha.enterprise.execute(site_key, { action });
        e.target.submit();
    });
}

// TODO: This is a temporary hack, introduce proper webpack exports
window.recaptchaFormSubmitHandler = recaptchaFormSubmitHandler;
