"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
document.querySelectorAll(".vote-button").forEach((button) => {
    button.addEventListener("click", () => __awaiter(void 0, void 0, void 0, function* () {
        const voteButtons = button.parentElement.querySelectorAll(".vote-button");
        const objectId = button.getAttribute("data-object-id");
        const value = button.getAttribute("value");
        let response;
        try {
            const url = `/api/ideas/${objectId}/vote/`;
            response = yield window.$axios.post(url, { value });
            if (response.status !== 204) {
                window.validateResponse(response, true);
            }
            if (GA_ENABLED) {
                gtag("event", `vote`, { value });
            }
        }
        catch (error) {
            window.toastWarning(error);
        }
        voteButtons.forEach((btn) => {
            const icon = btn.querySelector(".vote-icon");
            const voteCount = btn.querySelector(".vote-count");
            if (btn.getAttribute("type") === "up") {
                voteCount.textContent = response.data.upvote_count.toString();
                if (response.data.value === 1) {
                    icon.classList.add("fas");
                    icon.classList.remove("far");
                    btn.setAttribute("value", "0");
                }
                else {
                    icon.classList.remove("fas");
                    icon.classList.add("far");
                    btn.setAttribute("value", "1");
                }
            }
            else {
                voteCount.textContent = response.data.downvote_count.toString();
                if (response.data.value === -1) {
                    icon.classList.add("fas");
                    icon.classList.remove("far");
                    btn.setAttribute("value", "0");
                }
                else {
                    icon.classList.remove("fas");
                    icon.classList.add("far");
                    btn.setAttribute("value", "-1");
                }
            }
        });
    }));
});
