function addDismissListener(element) {
    const dismissObjects = [];

    element.querySelectorAll("[data-dismiss-target]").forEach((triggerEl) => {
        const options = {
            transition: "transition-opacity",
            duration: 1000,
            timing: "ease-out",
        };
        const instanceOptions = {
            id: Math.floor(Math.random() * 10000),
        };

        const dismiss = new Dismiss(element, triggerEl, instanceOptions);

        dismissObjects.push(dismiss);
    });

    setTimeout(() => {
        dismissObjects.forEach((dismiss) => dismiss.hide());
    }, 10000);
}

/**
 * @param {string} message
 */
export function toastWarning(message) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(
        `
        <div id="toast-anger"
             class="z-100 flex items-center p-4 mb-4 w-full max-w-xs text-primary-500 bg-white rounded-lg shadow dark:text-primary-400 dark:bg-primary-700"
             role="alert">
            <div class="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
               <i class="fa-solid fa-xmark w-5 h-5"></i>
            </div>
            <div class="ml-3 text-sm font-normal">${message}</div>
            <button type="button"
                    class="ml-auto -mx-1.5 -my-1.5 bg-white text-primary-400 hover:text-primary-900 rounded-lg focus:ring-2 focus:ring-primary-300 p-1.5 hover:bg-primary-100 inline-flex h-8 w-8 dark:text-primary-500 dark:hover:text-white dark:bg-primary-700 dark:hover:bg-primary-700"
                    data-dismiss-target="#toast-danger"
                    aria-label="Close">
                <i class="fa-solid fa-xmark w-5 h-5"></i>
            </button>
        </div>
    `,
        "text/html",
    );

    const toast = doc.body.children[0];
    document.querySelector(".messages").appendChild(toast);
    addDismissListener(toast);
}

/**
 * @param {string} message
 */
export function toastSuccess(message) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(
        `
        <div id="toast-success"
            class="z-100 flex items-center p-4 mb-4 w-full max-w-xs text-primary-500 bg-white rounded-lg shadow dark:text-primary-400 dark:bg-primary-700"
            role="alert">
            <div class="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                <i class="fa-solid fa-check w-5 h-5"></i>
            </div>
            <div class="ml-3 text-sm font-normal">${message}</div>
            <button type="button"
                    class="ml-auto -mx-1.5 -my-1.5 bg-white text-primary-400 hover:text-primary-900 rounded-lg focus:ring-2 focus:ring-primary-300 p-1.5 hover:bg-primary-100 inline-flex h-8 w-8 dark:text-primary-500 dark:hover:text-white dark:bg-primary-700 dark:hover:bg-primary-700"
                    data-dismiss-target="#toast-success"
                    aria-label="Close">
                <i class="fa-solid fa-xmark w-5 h-5"></i>
            </button>
        </div>
    `,
        "text/html",
    );

    const toast = doc.body.children[0];
    document.querySelector(".messages").appendChild(toast);
    addDismissListener(toast);
}

window.toastWarning = toastWarning;
window.toastSuccess = toastSuccess;
