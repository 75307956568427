document.addEventListener("DOMContentLoaded", () => {
    const stickyButton = document.getElementById("sticky-button");
    if (!stickyButton) return;

    const stickyButtonStyle = document.getElementById("stickyButtonStyle");
    const separators = document.getElementsByClassName("separator");
    const articleContent = document.getElementById("articleContent");

    if (stickyButton.hasAttribute("fixed-only")) {
        makeFixed();
    } else {
        window.addEventListener("scroll", checkArticlePosition);
    }

    function makeFixed() {
        stickyButton.classList.add("fixed");
        stickyButtonStyle.classList.remove("dark:bg-primary-950");
        stickyButtonStyle.classList.add("shadow", "dark:bg-primary-700");
        for (const separator of separators) {
            separator.classList.remove("hidden");
        }
    }

    function makeRelative() {
        stickyButton.classList.remove("fixed");
        stickyButtonStyle.classList.add("dark:bg-primary-950");
        stickyButtonStyle.classList.remove("shadow", "dark:bg-primary-700");
        for (const separator of separators) {
            separator.classList.add("hidden");
        }
    }

    function checkArticlePosition() {
        const articleRect = articleContent.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const stickyButtonHigh = 35;

        if (articleRect.bottom - stickyButtonHigh > viewportHeight) {
            makeFixed();
        } else {
            makeRelative();
        }
    }
});
