import $ from "jquery";
import Infinite from "./infinite";
import "../../css/main.css";

window.$ = window.jQuery = $;

window.addEventListener("load", () => {
    const loadMoreButton = $("#load-more-objects");
    const loadingMoreButton = $("#loading-more-objects");
    const infiniteContainer = $(".infinite-objects-container")[0];
    if (!infiniteContainer) return;

    const options = {
        element: infiniteContainer,
        more: ".infinite-more-objects-link",
        items: ".infinite-object",
        triggers: ".load-more-objects",
        enabled: true,
        onBeforePageLoad: function () {
            loadMoreButton.hide();
            loadingMoreButton.show();
        },
        onAfterPageLoad: function () {
            loadMoreButton.show();
            loadingMoreButton.hide();
        },
    };

    window.infiniteScroll = new Infinite(options);
});
// Timezone settings
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // e.g. "America/New_York"
document.cookie = `timezone=${timezone};path=/`;

document.addEventListener("DOMContentLoaded", () => {
    // Nav burger
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(
        document.querySelectorAll(".navbar-burger"),
        0,
    );
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
        // Add a click event on each of them
        $navbarBurgers.forEach((el) => {
            el.addEventListener("click", () => {
                // Get the target from the "data-target" attribute
                const target = el.dataset.target;
                const $target = document.getElementById(target);

                // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                el.classList.toggle("is-active");
                $target.classList.toggle("is-active");
            });
        });
    }

    // Register and Login page - Show password icon
    const showPasswordIcon = document.getElementById("show-password-icon");
    if (showPasswordIcon !== null) {
        showPasswordIcon.addEventListener("click", (event) => {
            const input = document.getElementById("password");
            if (input.type === "password") {
                input.type = "text";
                showPasswordIcon
                    .querySelector("svg")
                    .classList.replace("fa-eye-slash", "fa-eye");
            } else {
                input.type = "password";
                showPasswordIcon
                    .querySelector("svg")
                    .classList.replace("fa-eye", "fa-eye-slash");
            }
        });
    }

    //    Use a tag as link, avoiding using nested <a> tags
    document.querySelectorAll(".custom-link").forEach((link) =>
        link.addEventListener("click", (e) => {
            e.preventDefault();
            let url = link.getAttribute("url");

            // If target is <a> tag or in <a> tag, go to that url.
            const closest_link = e.target.closest("a");
            if (closest_link) {
                url = closest_link.href;
            }

            if (e.ctrlKey || e.metaKey) {
                return window.open(url, "_blank");
            }
            window.location = url;
        }),
    );
});

document.querySelectorAll(".scroll-to-top").forEach((button) => {
    // Set initial classes required for all buttons
    button.classList.add("opacity-0", "transition-opacity");

    let scrollContainer = window;
    const scrollContainerSelector = button.getAttribute(
        "data-scroll-container",
    );
    if (scrollContainerSelector) {
        scrollContainer = document.querySelector(scrollContainerSelector);
    }

    const buttonVisibilityOffset = parseInt(
        button.getAttribute("data-show-button-offset") | "1000",
    );

    button.addEventListener("click", () =>
        scrollContainer.scrollTo({ top: 0, behavior: "smooth" }),
    );

    scrollContainer.addEventListener("scroll", () => {
        const scrollPosition =
            scrollContainer === window ? scrollY : scrollContainer.scrollTop;

        if (scrollPosition > buttonVisibilityOffset) {
            button.removeAttribute("disabled");
            button.classList.remove("opacity-0");
        } else {
            button.setAttribute("disabled", "true");
            button.classList.add("opacity-0");
        }
    });
});

document.querySelectorAll("[ga-event]").forEach((button) =>
    button.addEventListener("click", () => {
        const eventName = button.getAttribute("ga-event");
        const dataName = button.getAttribute("ga-data-name");

        const data = {};
        if (dataName) {
            data["name"] = dataName;
        }
        if (GA_ENABLED) {
            gtag("event", eventName, data);
        }
    }),
);
